import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'Ever Water',
    useCase: 'PRODUCT DEVELOPMENT, INVESTMENT DECK',
    description: `Ever Water is a water brand that I had the rare opportunity to help bring to life from the producst inception. I worked hand in hand with the founders to understand the market place that their water would be competiting in. With Ever Water being a Chlorophyll H2O it provided a unique design challenge. The water is green and the goal was for the consumer to view the water through the bottle as well as allowing the branding to pop on the shelf. I also designed and laid out their investment deck to tell the brands story and how they can win in an oversaturated bottled water market.

    This product is still in development. `,
  },
  caseId: 'ever',
  contents: [
    'image0.jpg',
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
    'image4.jpg',
    'image5.jpg',
    'image6.jpg',
    'image7.jpg',
    'image8.jpg',
    'image9.jpg',
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
